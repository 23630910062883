import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import AOS from 'aos'
import Layout from "../layouts"
import { Helmet } from "react-helmet"


function myFunction() {
  var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
  var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  var scrolled = (winScroll / height) * 100;
  document.getElementById("progress-bar").style.width = scrolled + "%";
}

function addZero(d){
  return "0" + d;
}

function number_lead_zero(d){ 
  return d < 10 ? addZero(d) : d
}

function renderDate(x){
  // var dateTimeParts = x.split('T')
  var date = new Date(x);
  var day = date.getDay();
  var month = date.getMonth();
  var year = date.getFullYear();
  var shortDate = number_lead_zero(month) + '.' + number_lead_zero(day) + '.' + year;
  return(
    <div>{shortDate}</div>
  );
}

function checkAnimation(i, animatedIn){
  if(i === 0 && animatedIn === false){
    return 0
  }else{ 
    return 1
  }
}

function checkAnimationDelay(i, animatedIn){
  if(i === 0 && animatedIn === false){
    return 0
  }else if(i === 0 && animatedIn === true){
    return 1;
  }else{
    return 1
  }
}



class Blog extends Component {

  constructor(props){

    super(props);

    this.state = {
      filteredDataMaster: this.props.data.allWordpressWpBlog,
      filteredData: this.props.data.allWordpressWpBlog,
      master: this.props.data.allWordpressWpBlog,
      count: 3,
      countBy: 3,
      animatedIn: false,
    }
  }

  componentDidMount() {
    AOS.init();

    window.onscroll = function() {
      myFunction();
      AOS.refresh();
    };

    this.setState({
      filteredData: this.getFirstThreePosts(this.props.data.allWordpressWpBlog)
    });

  }

  getFirstThreePosts(x){
    if(x !== null){
      let newX = x.edges.slice(0, 3)
      let newArr = [{'edges': newX}];
      return newArr[0]
    }else{
      return x
    }
  }

  getNextThreePosts(master, count, countBy){
    let caseStudies = master
    let newX = master.edges.slice(count, count + countBy)
    let updatedWork = this.state.filteredData.edges.concat(newX);
    let newArr = [{'edges': updatedWork}];
    this.setState({
      filteredData: newArr[0],
      count: this.state.count + this.state.countBy
    });
  }

  getNextThreePostsKeyPress(e, master, count, countBy){
    var charCode = e.charCode || e.keyCode;
    if(charCode == 13) {
      let caseStudies = master
      let newX = master.edges.slice(count, count + countBy)
      let updatedWork = this.state.filteredData.edges.concat(newX);
      let newArr = [{'edges': updatedWork}];
      this.setState({
        filteredData: newArr[0],
        count: this.state.count + this.state.countBy
      });
    }
  }

  loadMoreButton(x){
    return(
      <div className={(this.state.filteredData.edges.length >=  this.state.filteredDataMaster.edges.length  ? 'all-articles inactive' : 'all-articles ')}>
        <span tabIndex="0" className="cta hover-line-down" onClick={() => this.getNextThreePosts(this.state.filteredDataMaster, this.state.count, this.state.countBy)} onKeyPress={(e) => this.getNextThreePostsKeyPress(e, this.state.filteredDataMaster, this.state.count, this.state.countBy)}>Load more articles</span>
      </div>
    )
  }

  filterGlobalData(id, x, e){
    let data = [];
    x.edges.map((post) => {
      if( post.node.blog_category.includes(id)){
        data.push(post);
      } 
    }); 
    var newArray = {"edges": data}

    var navElements = document.getElementsByClassName('category');
    for (var i = 0; i < navElements.length; ++i) {
      navElements[i].classList.remove("active");
    }

    e = e || window.event;
    var target = e.target || e.srcElement

    target.classList.add("active");

    this.setState({
      filteredDataMaster: newArray,
      filteredData: this.getFirstThreePosts(newArray),
      count: 3,
      countBy: 3,
      animatedIn: true
    });
    
  }

  filterGlobalDataKeyPress(id, x, e){

   var charCode = e.charCode || e.keyCode;
   if(charCode == 13) {

      let data = [];
      x.edges.map((post) => {
        if( post.node.blog_category.includes(id)){
          data.push(post);
        } 
      }); 
      var newArray = {"edges": data}

      var navElements = document.getElementsByClassName('category');
      for (var i = 0; i < navElements.length; ++i) {
        navElements[i].classList.remove("active");
      }

      e = e || window.event;
      var target = e.target || e.srcElement

      target.classList.add("active");

      this.setState({
        filteredDataMaster: newArray,
        filteredData: this.getFirstThreePosts(newArray),
        count: 3,
        countBy: 3,
        animatedIn: true
      });
    }
    
  }

  resetData(e){

    var navElements = document.getElementsByClassName('category');
    for (var i = 0; i < navElements.length; ++i) {
      navElements[i].classList.remove("active");
    }

    e = e || window.event;
    var target = e.target || e.srcElement

    target.classList.add("active");

    this.setState({
      filteredDataMaster: this.state.master,
      filteredData: this.getFirstThreePosts(this.state.master),
      count: 3,
      countBy: 3,
      animatedIn: true
    });
  }

  resetDataKeyPress(e){

    var charCode = e.charCode || e.keyCode;
    if(charCode == 13) {

      var navElements = document.getElementsByClassName('category');
      for (var i = 0; i < navElements.length; ++i) {
        navElements[i].classList.remove("active");
      }

      e = e || window.event;
      var target = e.target || e.srcElement

      target.classList.add("active");

      this.setState({
        filteredDataMaster: this.state.master,
        filteredData: this.getFirstThreePosts(this.state.master),
        count: 3,
        countBy: 3,
        animatedIn: true
      });
    }

  }

  render() {
    const data = this.props.data

    const metaData = this.props.data.allWordpressAcfOptions.group[0].edges[0].node.options.meta_data_by_page.blog

    let acfColor = '#F3A738'

    if (typeof window === 'undefined'){
      return (
      <Layout>
        <Helmet script={[{
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
              "@context": "http://schema.org",
              "@type": "WebPage",
              "url": "https://excelinstore.com/",
              "name": "Excel | Moving Retail Forward",
              "author": {
                  "@type": "Organization",
                  "name": "Excel",
                  "url": "https://excelinstore.com/",
                  "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "1021 International Drive",
                      "addressLocality": "Fergus Falls",
                      "addressRegion": "MN",
                      "postalCode": "56537",
                      "addressCountry": "USA"
                  },
                  "contactPoint": {
                      "@type": "ContactPoint",
                      "contactType": "customer support",
                      "telephone": "+1-218-998-3800",
                      "email": "contact@excelinstore.com"
                  }
              },
              "description": "We guide clients through the creation of in-store visual merchandising displays and fixtures.",
              "publisher": {
                  "@type": "Organization",
                  "name": "Excel",
                  "url": "https://excelinstore.com/",
                  "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "1021 International Drive",
                      "addressLocality": "Fergus Falls",
                      "addressRegion": "MN",
                      "postalCode": "56537",
                      "addressCountry": "USA"
                  }
              }
            })
          }]}>
          <html lang="en" />
          <meta charSet="utf-8" />
          <title>Excel | Blog</title>
          <meta name="description" content={metaData.description} />
          <meta property="og:title" content={"Excel | Blog"}  />
          <meta property="og:type" content="WebSite" />
          <meta property="og:url" content="https://excelinstore.com/" />
          <meta property="og:image" content={metaData.image_data.url} />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:description" content={metaData.description} />
          <meta property="og:site_name" content="Excel" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={"Excel | Blog"} />
          <meta name="twitter:description" content={metaData.description} />
          <meta name="twitter:image" content={metaData.image_data.url} />
        </Helmet>
         </Layout>
        )
    }else{
      var called = false
      window.addEventListener('mousedown', function() {
        if (called === false){
            let elem = document.getElementById('excel-global-header-inner')
            elem.classList.add('using-mouse');
            called = true
        }
      });

      return (
        <Layout>
          <Helmet script={[{
              type: 'application/ld+json',
              innerHTML: JSON.stringify({
                "@context": "http://schema.org",
                "@type": "WebPage",
                "url": "https://excelinstore.com/",
                "name": "Excel | Moving Retail Forward",
                "author": {
                    "@type": "Organization",
                    "name": "Excel",
                    "url": "https://excelinstore.com/",
                    "address": {
                        "@type": "PostalAddress",
                        "streetAddress": "1021 International Drive",
                        "addressLocality": "Fergus Falls",
                        "addressRegion": "MN",
                        "postalCode": "56537",
                        "addressCountry": "USA"
                    },
                    "contactPoint": {
                        "@type": "ContactPoint",
                        "contactType": "customer support",
                        "telephone": "+1-218-998-3800",
                        "email": "contact@excelinstore.com"
                    }
                },
                "description": "We guide clients through the creation of in-store visual merchandising displays and fixtures.",
                "publisher": {
                    "@type": "Organization",
                    "name": "Excel",
                    "url": "https://excelinstore.com/",
                    "address": {
                        "@type": "PostalAddress",
                        "streetAddress": "1021 International Drive",
                        "addressLocality": "Fergus Falls",
                        "addressRegion": "MN",
                        "postalCode": "56537",
                        "addressCountry": "USA"
                    }
                }
              })
            }]}>
            <html lang="en" />
            <meta charSet="utf-8" />
            <title>Excel | Blog</title>
            <meta name="description" content={metaData.description} />
            <meta property="og:title" content={"Excel | Blog"}  />
            <meta property="og:type" content="WebSite" />
            <meta property="og:url" content="https://excelinstore.com/" />
            <meta property="og:image" content={metaData.image_data.url} />
            <meta property="og:image:type" content="image/jpeg" />
            <meta property="og:description" content={metaData.description} />
            <meta property="og:site_name" content="Excel" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={"Excel | Blog"} />
            <meta name="twitter:description" content={metaData.description} />
            <meta name="twitter:image" content={metaData.image_data.url} />
            
          </Helmet>
          <div className="progress"><div id="progress-bar" style={{backgroundColor: acfColor}}></div></div>
          <div className="hero--wrapper">
            <div className="hero long">
              <h1 className="micro" dangerouslySetInnerHTML={{__html: 'Our Blog'}} data-aos="fade-up" data-aos-delay="400" data-aos-anchor=".hero"/>
              <p className="title h2" data-aos="fade-up" data-aos-delay="800" data-aos-anchor=".hero">{this.props.data.allWordpressAcfOptions.group[0].edges[0].node.options.blog.blog_title}</p>
            </div>
            <div className="content-wrapper-long" data-aos="fade-up" data-aos-delay="1200" data-aos-anchor=".hero">
              <ul className="filtering-categories">
                <li tabIndex="0" className="category active" onClick={(e) => this.resetData(e)} onKeyPress={(e) => this.resetDataKeyPress(e)}>All</li>
                { data.allWordpressWpBlogCategory.edges.map(({ node }, i) => (
                  <li tabIndex="0" onKeyPress={(e) => this.filterGlobalDataKeyPress(node.wordpress_id, this.state.master, e)} data-id={node.slug} className="category" onClick={(e) => this.filterGlobalData(node.wordpress_id, this.state.master, e)} key={"slug_" + i}>
                    {node.name}
                  </li>
                ))}
              </ul>
            </div>
            { this.state.filteredData.edges.map(({ node }, i) => (
              <Link to={'/blog/' + node.slug} css={{ textDecoration: `none` }} key={node.slug} className="blog-post-preview cf" data-aos="fade-up" data-aos-anchor={ checkAnimation(i, this.state.animatedIn) ? "" : ".hero"} data-aos-delay={checkAnimationDelay(i, this.state.animatedIn) ? "300" : "1400"}>
               {node.acf &&
                  node.acf.post_hero_image &&
                  <div className="hero--full-bleed blog">
                    <div className="image" style={{'backgroundImage': "url(" + node.acf.post_hero_image.url + ")"}}>
                    </div>
                  </div>
                }
                <div className="blog-link">
                  {node.title}
                </div>
                <div to={'/blog/' + node.slug} className="cta blog_text_link">Read More<span><span></span></span></div>
                <div className="blog_date">
                  { renderDate(node.date) }
                </div>
              </Link>
            ))}
            { this.state.filteredData.edges.length <= 0 &&
              <div className="no-posts">
                No Posts
              </div>
            }
            { this.props.data.filteredDataMaster !== null &&
                <div>
                  {
                    this.loadMoreButton(this.props.data.filteredDataMaster)
                  }
                </div>
              }
          </div>
        
          </Layout>
        )
      }
    
  }
}

export default Blog

// Set here the ID of the home page.
export const pageQuery = graphql`
  query {
    allWordpressAcfOptions {
      group(field: id) {
        edges {
          node {
            options {
              meta_data_by_page {
                blog {
                  meta_description
                  image_data {
                    url
                    alt
                  }
                }
              }
              blog{
                blog_title
              }
            }
          }
        }
      }
    }
    allWordpressPage {
      edges {
        node {
          id
          title
          excerpt
          slug
          date(formatString: "MMMM DD, YYYY")
        }
      }
    }
    allWordpressWpBlogCategory {
      edges {
        node {
          wordpress_id
          slug
          name
        }
      }
    }
    allWordpressWpBlog {
      edges {
        node {
          slug
          title
          date
          blog_category
          acf {
            post_hero_image {
              alt
              caption
              url
              title
            }
          }
        }
      }
    }
  }
`
